//import React from 'react';
import '../styles/loader.css';

// BankDetailsForm.js
import React from 'react';

const Loader = () => {
    return (
        <div className="loader-bg">
            <div className="loader"></div>
        </div>
    );
}

export default Loader;