import brands from '../brands.json';

const BASE_URL = process.env.REACT_APP_API_URL;

async function fetchAPI(endpoint, method = 'GET', params = {},data = null) {
    const url = new URL(`${BASE_URL}${endpoint}`);

    let headers = {
        'x-access-token': process.env.REACT_APP_ACCESS_TOKEN,
        'x-merchant-id': brands[process.env.REACT_APP_BRAND].merchantId,
        'ngrok-skip-browser-warning': 'true'
         // token ? token : ''
    };

    if (method === 'GET' && params) {
        Object.keys(params).forEach(key => params[key] && url.searchParams.append(key, params[key]));
    }

    // Check if data is FormData and adjust headers and body accordingly
    if (!(data instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
        data = data ? JSON.stringify(data) : null;
    }

    const config = {
        method: method,
        headers: headers,
        body: method !== 'GET' ? data : null
    };

    try {
        const response = await fetch(url.toString(), config);
        if (!response.ok) {
            const errorMessage = await response.text(); // Retrieves text (including plain messages) from the response body
            console.error(errorMessage);
            throw new Error(errorMessage || 'يوجد خطأ في اتصال الانترنت حاول مجددا لاحقا'); // Throw an error with the server's response message
        }
        return response.json(); // Parses JSON response into native JavaScript objects
    } catch (error) {
        console.error('خطأ:', error);
        throw error;
    }
}

const apiService = { // (endpoint, Method, params, data/body ) 
    sendOTP: (phoneNumber) => fetchAPI('/otp/sendOTP', 'POST', null,{ mobile: phoneNumber }),
    verifyOTP: (data) => fetchAPI('/otp/verifyOTP', 'POST', null,data),
    uploadReturnImages: (formData) => fetchAPI('/upload/salla.sites', 'POST',null, formData),
    createReturnRequest: (data) => fetchAPI('/returns', 'POST',null, data),
    getOrders: (phoneNumber) => fetchAPI('/orders', 'GET', { mobile: phoneNumber }),
    getReturns: (phoneNumber,orderId) => fetchAPI('/returns', 'GET', { mobile: phoneNumber,orderId: orderId }),
    getOrderById: (orderId) => fetchAPI(`/orders/${orderId}`, 'GET'),
    // Add more API functions here
};
export default apiService;
