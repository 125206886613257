import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/confirmation.css';

const ThankYouPage = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate('/orders');
  //   }, 3000); // Redirect after 5 seconds

  //   return () => clearTimeout(timer);
  // }, [navigate]);

  return <div className="thankYouContainer">
 <img src="/images/checkmark-circle.svg" className="checkmarkImage" alt="Checkmark" />
  <h1>تم تسجيل طلب الإرجاع بنجاح</h1>
  <h3> سيتم إرسال لكم خطوات الإرجاع عبر الإيميل المسجل في طلب الإرجاع</h3>
</div>
};

export default ThankYouPage;