import React from 'react';
import '../styles/statusHistory.css'; // make sure the path to your CSS file is correct
import { svgIcons } from '../utils/SvgIcons'; 

const StatusHistory = ({ currentSlug }) => {
    const statusMap = {
        pending: 'قيد الانتظار',
        accepted: 'مقبول',
        rejected: 'مرفوض',
        police: 'تم اصدار بوليصة الارجاع',
        picked: 'الشحنة متوجهة للمتجر', // from the customer ? //بإنتظار إعادة الإرجاع
        transferred: 'تم إعادة المبلغ',
        matched: 'تمت مطابقة المنتجات بنجاح',
        mismatched: 'المنتجات غير متطابقة',
        closed: 'ارجاع مكتمل',
    };

    const statusSteps = [
        'pending',
        'decision', // Placeholder for either 'accepted' or 'rejected'
        'police',
        'picked',
        'transferred',
        'closed',
    ];

    // This function determines the status index accounting for the accepted/rejected logic.
    const getCurrentStatusIndex = (slug) => {
        if (slug === 'rejected' || slug === 'accepted') {
            return 1; // 'both refers to the second step
        }
        if (slug === 'matched' || slug === 'mismatched') {
            return 3; // 'both refers to the second step
        }
        const index = statusSteps.indexOf(slug);
        return index === -1 ? 0 : index; //If the slug not found, return pending
    };

    const currentStatusIndex = getCurrentStatusIndex(currentSlug);

      // Function to determine the display text
      const getDisplayText = (slug) => {
        if (slug === 'decision') {
            if (currentStatusIndex === 1) {
                return statusMap[currentSlug]; // Return the current status if at the decision step
            } else if (currentStatusIndex > 1) {
                return statusMap["accepted"]; // Return 'Accepted' if past the decision step
            } else {
                console.log("inside third If")
                return "قبول | رفض"; // Return 'Placeholder' if before the decision step
            }
        }
        return statusMap[slug] || slug; // Default return for other statuses
    };

       // Function to determine the SVG icon
       const getSVGSlug = (slug) => {
        if (slug === 'decision') {
            if (currentStatusIndex === 1) {
                return currentSlug; // Return the current status if at the decision step
            } else if (currentStatusIndex > 1) {
                return "accepted"; // Return 'Accepted' if past the decision step
            } 
        }
        return slug; // Default return for other statuses
    };

    return (
        <div className="status-history">
            {statusSteps.map((slug, index) => {
                const isRejected = currentSlug === 'rejected' && index === 1;
                const isActive = index <= currentStatusIndex;
                const displayText = getDisplayText(slug);

                return (
                    <div key={slug} className={`status-step ${isRejected ? 'status-rejected' : ''}`}>
                        <div className={`status-icon ${isActive ? 'status-icon-active' : ''} ${isRejected ? 'status-icon-rejected' : ''}`}>
                        {svgIcons[getSVGSlug(slug)]}
                        </div>
                        <div className={`status-text ${isActive ? 'status-text-active' : ''} ${isRejected ? 'status-text-rejected' : ''}`}>
                            {displayText}
                        </div>
                        {index < statusSteps.length - 1 && <div className={`status-line ${isActive && !isRejected ? 'status-line-active' : ''}`}></div>}
                    </div>
                );
            })}
        </div>
    );
};

export default StatusHistory;


