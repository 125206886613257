import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import UserDataContext from '../session/UserDataContext'; // Import the context
import getPaymentMethodDetails from '../utils/payments_enum';
import BankDetailsForm from '../components/bankForm';
import QuantityControls from '../components/quantityControls';
import Loader from '../components/loader';
import apiService from '../services/api.service';
import { useErrorDialog } from '../global/errorContext';
import '../styles/orderDetails.css'; // Import the CSS module


const OrderDetailsPage = () => {
  const { orderId } = useParams();
  const { userData } = useContext(UserDataContext); // Access userData from context
  const navigate = useNavigate();
  const { showError } = useErrorDialog();
  const [order, setOrder] = useState(null);
  const [returnItems, setReturnItems] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [email, setEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState({ accountOwner: '', bankName: '', Iban: '' });

  const returnReasons = [
    { value: "quality", label: "مشكلة في جودة المنتج" },
    { value: "description", label: "المنتج غير مطابق لوصف الموقع" },
    { value: "damaged", label: "وجود تلف أو ضرر في المنتج" },
    { value: "unwanted", label: "لم اعد أرغب بالمنتج" },
    { value: "size", label: "مقاس المنتج غير مناسب" },
    { value: "expect", label: "ليس كما توقعت" }, // open field
    { value: "mistake", label: "لقد طلبت بالخطأ" },
    { value: "wrong", label: "ليس طلبي" },
    { value: "other", label: "غير ذلك" } // open field
];

  useEffect(() => {
    const fetchOrder = async () => {
      setIsLoading(true); // Start loading

      try {
        const order = await apiService.getOrderById(orderId);
        console.log('Order is:', order);

        setOrder(order);
        setPaymentDetails(getPaymentMethodDetails(order.payment_method))

        // Once we have the data, we can set the return items state
        setReturnItems(order.products.map(product => ({
          sku: product.sku, // change it to sku
          isSelected: false,
          quantity: 0,
          thumbnail: product.img,
          price: product.price,
          returnReason: '',
          reasonKey: '',
          images: []
        })));

      } catch (error) {
        showError(error.toString());
      } finally {
        setIsLoading(false); // Stop loading regardless of outcome
      }
    };

    if (orderId && userData.phoneNumber) {
      fetchOrder();
    } else {
      // Handle case where mobile is not provided, e.g., redirect
      navigate('/'); // Adjust as needed
    }
  }, [orderId, userData.phoneNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if at least one product is selected
    const isAnyProductSelected = returnItems.some(item => item.isSelected);
    if (!isAnyProductSelected) {
      // If no products are selected, alert the user and stop the submission
      showError("يرجى اختيار منتج واحد على الأقل للإرجاع");
      return;
    }

    // Validation for selected products' details
    const allFieldsFilled = returnItems.every(item => {
      return !item.isSelected || // Skip unselected items in this validation
        (item.quantity > 0 && item.returnReason && item.images.length > 0);
    });

    if (!allFieldsFilled) {
      // Display a message to the user indicating the validation failure
      showError("يرجى التأكد من أن جميع المنتجات المحددة لديها كمية أكبر من الصفر، وتم اختيار سبب للإرجاع، وتم رفع صورة واحدة على الأقل");
      return; // Exit the function early to prevent submitting the form
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email.trim() || !emailRegex.test(email)) {
      showError('يجب إدخال بريد إلكتروني بصيغة صحيحة'); // Check if the email is empty
      return
    }

    if (!termsAccepted){
      showError("يجب قبول سياسة الاسترجاع"); // Check if terms are not accepted
      return
    }
    // Process the form data
    console.log("Return Items are: ", returnItems)

    setIsLoading(true)

    // Upload images first
    try {
      for (let item of returnItems) {
        if (item.isSelected && item.images.length) {
          const formData = new FormData();
          item.images.forEach(image => formData.append('files', image));

          const response = await apiService.uploadReturnImages(formData);
          console.log("Server response:", response);

          // Assume the server responds with array of URLs or similar identifiers
          item.imageUrls = response.data.map(file => file.url);
          console.log("Images are uploaded and the URLs are: ", item.imageUrls)
        }
      }

      const body = {
        merchant: order.merchant,
        order_ref: orderId,
        mobile: userData.phoneNumber,
        email: email,
        products: returnItems.filter(item => item.isSelected),
        bank: bankDetails,
        order: order._id,
        payment_method: order.payment_method,
        shipment: order.shipment,
        order_delivered_at: order.delivered_at,
        order_discounts: order.discounts,
      }

      const returnRequest = await apiService.createReturnRequest(body);
      console.log('Return Request Submiited Successfully: ', returnRequest);
      navigate('/thank-you', { replace: true });

    } catch (error) {
      showError(error.message);
    } finally {
      setIsLoading(false);  // Deactivate loader after operation
    }
  };

  const handleSelectChange = (index, isSelected) => {
    // Update the isSelected state based on the checkbox
    setReturnItems(currentItems =>
      currentItems.map((item, itemIndex) =>
        index === itemIndex ? { ...item, isSelected: isSelected } : item
      )
    );
  };

  const handleDecrement = (index) => {
    setReturnItems(returnItems.map((item, itemIndex) =>
      index === itemIndex ? { ...item, quantity: Math.max(item.quantity - 1, 0) } : item // ensure quantity doesn't go below 0
    ));
  };

  const handleIncrement = (index, maxQuantity) => {
    setReturnItems(returnItems.map((item, itemIndex) =>
      index === itemIndex ? { ...item, quantity: Math.min(item.quantity + 1, maxQuantity) } : item // ensure quantity doesn't exceed max
    ));
  };

  const handleBankChange = (e) => {

    let { name, value } = e.target; // Destructure the name and value from the event's target

    if (name === 'Iban') {
      // Remove spaces from the value first
      value = value.replace(/\s+/g, '');
      // Then check if the length exceeds the maxLength, if so, truncate the value
      const maxLength = 24; // Assuming the IBAN maximum length
      if (value.length > maxLength) {
        value = value.substring(0, maxLength);
      }
    }

    setBankDetails(prevDetails => ({
      ...prevDetails, // Spread the previous bankDetails to maintain other unchanged properties
      [name]: value // Use the input's name to determine which bankDetail to update
    }));
  };

  const handleReasonChange = (index, reason, key) => {
    setReturnItems(returnItems.map((item, itemIndex) =>
      index === itemIndex ? { ...item, returnReason: reason, reasonKey: key} : item
    ));
  };

  const handleImageUpload = (index, e) => {
    console.log("index of the product is: ", index)
    const uploadedImages = returnItems[index].images
    console.log("Product images are: ", uploadedImages);

    const newImages = Array.from(e.target.files).slice(0, 3);

    if (uploadedImages.length + newImages.length <= 3) {
      setReturnItems(currentItems =>
        currentItems.map((item, itemIndex) =>
          index === itemIndex ? { ...item, images: [...item.images, ...newImages] } : item
        )
      );
    } else {
      showError('يمكنك فقط رفع ما يصل إلى 3 صور لكل منتج');
    }
    e.target.value = null; // Reset the file input after the files have been selected
    // console.log("return items are: ", returnItems);
  };

  const handleDeleteImage = (productIndex, imageIndex) => {
    const updatedReturnItems = returnItems.map((item, index) => {
      if (index === productIndex) {
        // Remove the image at imageIndex
        const updatedImages = item.images.filter((_, i) => i !== imageIndex);
        return { ...item, images: updatedImages };
      }
      return item;
    });

    setReturnItems(updatedReturnItems);
  };

  return (
    <div className="request-form">
      {isLoading ? (
        <Loader /> // Your loader here. Could be a spinner or custom loader component.
      ) : (
        <form onSubmit={handleSubmit} className="order-form">
          {order?.products.map((product, index) => (

            <div key={product.id} className={`product-row ${!returnItems[index].isSelected ? 'disabled-overlay' : ''}`}>
              {/* Conditional Overlay */}
              {/*!returnItems[index].isSelected && <div className="overlay"></div>*/}

              <div className="product-item">

                <div className="product-details">
                  <input
                    type="checkbox"
                    className="product-checkbox"
                    checked={returnItems[index].isSelected}
                    onChange={e => handleSelectChange(index, e.target.checked)}
                  />
                  <img src={product.img} alt={product.name} className="product-image" />
                  <div className="text-details">
                    <p className="product-title">{product.name} {product.sku}</p>
                    <p className="product-price">{product.price} رس</p>
                    {product.options?.map((option, index) => (
                      <div>
                        <p className="product-options">{option.name}: {option.value.name}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="quantity-details">
                  <p>أقصى كمية: {product.qty}</p>
                  <QuantityControls
                    quantity={returnItems[index].quantity}
                    maxQuantity={product.qty}
                    onIncrement={() => handleIncrement(index, product.qty)}
                    onDecrement={() => handleDecrement(index)}
                  />
                </div>

              </div>
              <div className='selections'>
                <p className="required-field">سبب الإرجاع</p>
                <select
                  className="return-reason"
                  value={returnItems[index].reasonKey}
                  onChange={(e) => {
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    handleReasonChange(index, selectedOption.text, selectedOption.value);
                  }}
                >
                  <option value="" className="required-field">يرجى ذكر سبب إرجاع المنتج</option>
                  {returnReasons.map((reason, reasonIndex) => (
                    <option key={reasonIndex} value={reason.value}>{reason.label}</option>
                  ))}
                </select>

                <div className="browse-img">
                  <p className="required-field">صور المنتج المرتجع على الطبيعة</p>
                  <input
                    type="file"
                    multiple
                    id={`image-upload-${index}`}
                    style={{ display: 'none' }} // Hide the actual file input
                    onChange={(e) => handleImageUpload(index, e)}
                    accept="image/*" // Only allow image files to be selected
                  />
                  <button
                    type="button" // Ensure it's a button with type="button" to prevent form submission
                    onClick={
                      () => {
                        console.log(`Trying to open image-upload-${index}`);
                        document.getElementById(`image-upload-${index}`).click()
                      }}
                  >
                    استعراض مكتبة الصور
                  </button>
                  <p className="">تصوير المنتج بوضوح شرط لقبول طلب الارجاع **</p>

                  {/* Preview Uploaded images and the ability to delete them */}
                  <div className="image-preview-container">
                    {returnItems[index].images.map((imageFile, imgIndex) => (
                      <div key={imgIndex} className="image-preview">
                        <img src={URL.createObjectURL(imageFile)} alt={`Preview ${imgIndex}`} />
                        <button
                          type="button"
                          className="delete-image-btn"
                          onClick={() => handleDeleteImage(index, imgIndex)}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>
          ))}

          <div className="payment-method-container">
            <h3>وسيلة دفع الطلب: </h3>
            <img src={paymentDetails?.icon} alt={paymentDetails?.name} className="payment-method-logo" />
            <p>{paymentDetails?.name}</p>
            <p>{paymentDetails?.policy}</p>
          </div>

          <div className="email-container">
            <h3>البريد الالكتروني:</h3>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="البريد الالكتروني"
            />
            <p> لاستقبال اشعارات حالة طلب الارجاع</p>
          </div>

          {
            order?.payment_method !== 'tabby_installment' && order?.payment_method !== 'tamara_installment' && (
              <BankDetailsForm
                bankDetails={bankDetails}
                handleBankChange={handleBankChange}
              />
            )
          }

          <div className="terms-container">
             <div className="terms-agreement-container">
            <input
              type="checkbox"
              className="terms-checkbox"
              checked={termsAccepted}
              onChange={e => setTermsAccepted(e.target.checked)}
            />
             <p> 
              اوافق على إعادة المبلغ خلال  1 - 14 يوم عمل كحد أقصى من تاريخ وصول المنتجات الى مستودعات المتجر
                .
              <Link to={`/terms-conditions`}>نرجوا الاطلاع على سياسة الاسترجاع للمتجر</Link>    
              </p>
             </div>
            </div>

            <button className='submit-return-request'
              type='submit'
            >
              إرسال الطلب
            </button>
        </form >
      )}
    </div >
  );

};

export default OrderDetailsPage;
