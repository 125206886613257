import React from 'react';
import NavBar from './components/navBar'
import {Routes, Route } from 'react-router-dom'; // Import necessary components from react-router-dom
import './styles/App.css';
import UserDataProvider from './session/UserDataProvider';
import { ErrorDialogProvider } from './global/errorContext';
import ErrorDialog from './components/errorDialog'; // Ensure this import is correct

import LoginPage from './pages/login';
import OTPPage from './pages/otp'; 
import OrdersPage from './pages/orders'; 
import OrderDetailsPage from './pages/orderDetails'; 
import ReturnsPage from './pages/returns'; 
import ThankYouPage from './pages/confirmation'; 
import TermsPage from './pages/terms'; 
import SetFavicon from './components/setFavicon';


function App() {
  return (
      <div>
        <SetFavicon />
        <NavBar />
        <h3>منصة إرجاع الطلبات</h3>
        <UserDataProvider>
          <ErrorDialogProvider>
          <ErrorDialog />
        <Routes> {/* Define your routes within Routes component */}
          <Route path="/" element={<LoginPage />} /> {/* Define the route for LoginPage */}
          <Route path="/otp" element={<OTPPage />} /> {/* Define the route for OrdersPage */}
          <Route path="/orders" element={<OrdersPage />} /> {/* Define the route for OrdersPage */}
          <Route path="/order/:orderId" element={<OrderDetailsPage />} />
          <Route path="/returns/:orderId" element={<ReturnsPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/terms-conditions" element={<TermsPage />} />
          {/* You can add more routes here as needed */}
        </Routes>
        </ErrorDialogProvider>
        </UserDataProvider>
      </div>
  );
}

export default App;
