import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserDataContext from '../session/UserDataContext'; // Import the context
import PhoneField from '../components/phoneField'
import apiService from '../services/api.service';
import { useErrorDialog } from '../global/errorContext';

import '../styles/login.css';

const LoginPage = () => {

    // to navigate to orders list page ...
    const navigate = useNavigate();
    const { showError } = useErrorDialog();

    // State to manage the phone number value
    const { userData, setUserData } = useContext(UserDataContext);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // New state for loading


    // Handler function to update the phone number state
    const handlePhoneNumberChange = (newValue) => {
        setPhoneNumber(newValue);
    };

    useEffect(() => {
        // Reset phoneNumber state to empty
        setPhoneNumber('');
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the phoneNumber is empty
        if (phoneNumber.length !== 9) {
            setErrorMessage('يجب أن يكون رقم الجوال ٩ أرقام  ');
            return; // Stop further execution
        } else {
            setErrorMessage(''); // Clear any previous error message
        }

        // Store the phone number in context
        setUserData({ ...userData, phoneNumber: `966${phoneNumber}` });

        // Add your login logic here, such as sending credentials to the server
        setIsLoading(true); // Set loading state to true
        try {
            const otpResponse = await apiService.sendOTP(`966${phoneNumber}`);
            console.log('The sendOtp code is:', otpResponse);
            //navigate('/orders');
            navigate('/otp', { state: { otpResponse } });
        } catch (error) {
            showError(error.toString());
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    return (
        <div className='login-container'>
            <form onSubmit={handleSubmit} className='login-form'>
                {/*<label htmlFor="phoneNumber">Phone Number:</label>*/}
                {/* Use the PhoneNumberField component */}
                <p className='enter-phone-label'>ادخل رقم الجوال المسجل في الطلب </p>
                <PhoneField className='phone-field'
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                />
                {/* Other login form elements */}
                <button type="submit" disabled={isLoading}>
                    {isLoading ? <span className="spinner"></span> : 'الدخول'}
                </button>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </form>
        </div>
    );
}

export default LoginPage;
